import React from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"
import '../components/cookiesBanner.scss'

const Cookies = () => {
  return (
    <Layout>
      <GatsbySeo
        title='Theia | A clínica moderna centrada na gestante'
        description='O único serviço personalizado de saúde que conecta gestantes à um time de obstetras e especialistas em gestação. Do pré-natal ao parto.'
        language='pt-br'
        canonical='https://blog.theia.com.br/'
        openGraph={{
          type: 'website',
          url: 'https://blog.theia.com.br/',
          title: 'Theia | A clínica moderna centrada na gestante',
          description: 'O único serviço personalizado de saúde que conecta gestantes à um time de obstetras e especialistas em gestação. Do pré-natal ao parto.',
          images: [
            {
              url: 'https://theia.com.br/theia.png',
              width: 250,
              height: 367,
              alt: 'Og Imagem Alt',
            },
          ],
        }}
        metaTags={[{
          name: 'dc:title',
          content: 'Theia | A clínica moderna centrada na gestante'
        }, {
          name: 'dc:description',
          content: 'O único serviço personalizado de saúde que conecta gestantes à um time de obstetras e especialistas em gestação. Do pré-natal ao parto.'
        }, {
          name: 'dc:language',
          content: 'BR',
        }, {
          name: 'reply-to',
          content: 'suporte@theia.com.br',
        }, {
          name: 'author',
          content: 'Theia',
        }, {
          name: 'description',
          content: 'O único serviço personalizado de saúde que conecta gestantes à um time de obstetras e especialistas em gestação. Do pré-natal ao parto.',
        }, {
          name: 'keywords',
          content: 'Maes, pais, rede, apoio, bem-sucedidos',
        },
        , {
          property: 'twitter:card',
          content: 'summary_large_image',
        }
      ]}
      />
      <div className="wrapper-section-cookies">
      <h1>POLÍTICA DE COOKIES, <i>WEB BEACONS</i>, PUBLICIDADE E OUTRAS TECNOLOGIAS</h1>
      <p><i>Data da última atualização: 18 de novembro de 2020</i></p>
      <p>A <strong>THEIA</strong> poderá utilizar de cookies, <i>web beacons</i>, 
          SDKs ou outras tecnologias de rastreamento, incluindo rastreadores e
          identificadores de dispositivos eletrônicos enquanto você utilizar a
          Plataforma Theia. Estes arquivos, basicamente, são arquivos que armazenam,
          de forma temporária, o que você está visitando. Com isso, podemos
          realizar algumas análises estatísticas a respeito do uso de uma parte específica
          (ou do todo) da Plataforma, sendo importantes para podermos acompanhar o uso dos
          usuários, acompanhando quais partes mais acessadas, e quais eventualmente podem
          ser melhoradas ou ter novas funcionalidades implementadas. Também, com estes arquivos,
          podemos conhecer os interesses dos usuários, padrões de navegação e comportamento,
          podendo oferecer então um serviço mais assertivo, além de publicidade e
          conteúdos de seu interesse.</p>
      <p>Entretanto, é possível que você queira desativar estes <i>web beacons</i> e
      &nbsp;<i>cookies</i>, ou mesmo descadastrar-se da nossa lista de e-mails promocionais –
      você pode fazer isso a qualquer momento. No entanto, caso você opte por descadastrar-se,
      é possível que você não consiga aproveitar e usar todos os recursos que oferecemos da
      forma como eles foram desenvolvidos e pensados.</p>
      <p>Optando por desativá-los, há, para cada navegador diferente que você utilizar,
          um procedimento que você precisará fazer. Aqui, listamos os principais navegadores,
          com os procedimentos de cada um deles (caso o link deles deixe de funcionar, você pode
          procurar na internet como fazer, e irá encontrar a resposta). Só lembrando que cada um
          desses navegadores possui tecnologias próprias, <i>cookies</i> e políticas de privacidade
          próprias:</p>
      <ul className="cookies-list">
          <li>
          <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&oco=1&hl=pt-BR" target="_blank" className="cookies-link">Google Chrome</a>
          </li>
          <li>
          <a href="https://support.microsoft.com/pt-br/topic/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank" className="cookies-link">Internet Explorer</a>
          </li>
          <li>
          <a href="https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank" className="cookies-link">Microsoft Edge</a>
          </li>
          <li>
          <a href="https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s" target="_blank" className="cookies-link">Mozilla Firefox</a>
          </li>
          <li>
          <a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank" className="cookies-link">Opera</a>
          </li>
          <li>
          <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac" target="_blank" className="cookies-link">Safari</a>
          </li>
      </ul>
      </div>
    </Layout>
  )
}

export default Cookies






